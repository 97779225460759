export default {
    methods: {
        //点击记录
        doOperationRecord(id, type) {//type 0 跳转 1 翻译 2 回首页 3 关注 4 分享 5点赞 6 收藏
            let url = this.$tools.getURL(this.$urls.article['operation'], {
                id,
                type
            });
            this.$axios
                .get(url)
                .then(() => { })
                .catch(() => { });
        },

    }
}
